import { emitError, emitErrorWithFallback } from "@/event-bus";
import {
  CreateDakosyExportRequestArticle,
  CustomsHandlingConsolidationViewModel,
  Int32StringKeyValueItem,
  ShipmentApi,
} from "@/openapi";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { downloadFile } from "@/helpers/downloadHelper";
import { DataAction } from "@/store/dataModule";
import { AddMrnForm } from "@/components/dialogs/AddMrnDialog";
import AddMrnDialog from "@/components/dialogs/AddMrnDialog.vue.html";
import { DakosyExportData } from "@/types/types";
import DakosyExportAusfuhrInfoDialog from "@/components/dialogs/DakosyExportAusfuhrInfoDialog.vue";

interface DakosyExportButtonProperties {
  dakosyButtonIcon?: string;
  dakosyButtonColor?: string;
  dakosyButtonText?: string;
  dakosyButtonLoading?: boolean;
}

type EnrichedCustomsHandlingConsolidationViewModel =
  CustomsHandlingConsolidationViewModel & DakosyExportButtonProperties;

const DDPHOLDSHIPMENTABOVE1000 = 1;
const DAPHOLDSHIPMENTABOVE1000 = 8;

const api = new ShipmentApi(undefined, "");

@Component({
  components: {
    AddMrnDialog,
    DakosyExportAusfuhrInfoDialog,
  },
})
export default class ShipmentsOfConsolidation extends Vue {
  @Prop({ required: true })
  consolidation!: string;

  headers: DataTableHeader[] = [
    {
      text: "HAWB",
      align: "start",
      value: "hawb",
      groupable: false,
      sortable: false,
    },
    { text: "Pieces", value: "pieces", groupable: false, sortable: false },
    { text: "Weight", value: "weight", groupable: false, sortable: false },
    {
      text: "Multi piece complete",
      value: "multiPieceComplete",
      groupable: false,
      sortable: false,
    },
    {
      text: "Export MRN",
      value: "exportMrn",
      groupable: false,
      sortable: false,
    },
    { text: "Value", value: "value", groupable: false, sortable: false },
    {
      text: "Value currency",
      value: "valueCurrency",
      groupable: false,
      sortable: false,
    },
    {
      text: "Warehouse location",
      value: "warehouseLocation",
      groupable: false,
      sortable: false,
    },
    {
      text: "Incoterm",
      value: "incoterm",
      groupable: false,
      sortable: false,
    },
    {
      text: "Category",
      value: "category",
      groupable: false,
      sortable: false,
    },
    { text: "", value: "actions", align: "end", sortable: false },
  ];

  options: DataOptions = {
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  footerOptions = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
  };
  totalAmountOfItems: number = 0;

  isLoading = false;
  items: EnrichedCustomsHandlingConsolidationViewModel[] = [];
  currentItem: EnrichedCustomsHandlingConsolidationViewModel | null = null;

  dakosyExportStatus = {
    createExport: 1,
    createdExport: 2,
    sendToDakosy: 3,
    error: 4,
  };

  consolidationLoading = false;
  addExportMrnLoading = false;
  showAddExportMrnDialog = false;
  showDialogAusfuhrInfo = false;

  @Watch("consolidation", { immediate: true })
  consolidationWatcher(newVal: string, oldVal: string) {
    if (!!newVal) {
      this.onConsolidationLoad();
    }
  }

  shipmentOptionsTimeoutDelay = 0;
  @Watch("options")
  onShipmentOptionsChanged(newVal: any, oldVal: any) {
    if (this.consolidationLoading) {
      return;
    }

    clearTimeout(this.shipmentOptionsTimeoutDelay);
    this.shipmentOptionsTimeoutDelay = setTimeout(() => {
      this.getShipmentsOfConsolidation();
    }, 250);
  }

  async onConsolidationLoad() {
    this.consolidationLoading = true;
    this.items = [];
    await this.getShipmentsOfConsolidation(1);
    this.consolidationLoading = false;
  }

  async created() {
    await this.$store.dispatch(DataAction.FetchCustomsHandlingCategories);
  }

  async getShipmentsOfConsolidation(page?: number) {
    page ??= this.options.page;
    this.isLoading = true;
    try {
      const response = await api.getShipmentsOfConsolidation(
        this.consolidation,
        undefined,
        page,
        this.options.itemsPerPage,
      );
      this.items = response.data.items ?? [];
      this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
    } catch {
      emitError("Something went wrong while retrieving the shipments.");
    }

    this.isLoading = false;
  }

  getCategoryName(category: number) {
    return this.categories.find((c) => c.key === category)?.value ?? "";
  }

  get categories(): Int32StringKeyValueItem[] {
    return this.$store.getters.customsHandlingCategories;
  }

  get dataTableItems() {
    return this.items.map((c) => ({
      ...c,
      ...this.getDakosyButtonProperties(c.dakosyExportStatus),
    }));
  }

  async exportConsolidationWithConsol(id: number) {
    try {
      const response = await api.exportShipment(
        { hasConsolidation: true, shipmentIds: [id] },
        {
          responseType: "blob",
        },
      );

      downloadFile(response.data, "Consolidated-" + Date.now() + ".xlsx");
    } catch {
      emitError("Something went wrong while creating the export declaration.");
    }
  }

  hasShipmentRenderCategory(category: number) {
    return (
      category == DDPHOLDSHIPMENTABOVE1000 ||
      category == DAPHOLDSHIPMENTABOVE1000
    );
  }

  getDakosyButtonProperties(dakosyExportStatus: number) {
    let dakosyButtonColor: string = "";
    let dakosyButtonIcon: string = "";
    let dakosyButtonText: string = "";

    if (dakosyExportStatus === this.dakosyExportStatus.createExport) {
      dakosyButtonColor = "primary";
      dakosyButtonIcon = "mdi-export-variant";
      dakosyButtonText = "Create Dakosy export";
    } else if (dakosyExportStatus === this.dakosyExportStatus.createdExport) {
      dakosyButtonColor = "blue";
      dakosyButtonIcon = "mdi-sort-clock-descending-outline";
      dakosyButtonText = "Created export";
    } else if (dakosyExportStatus === this.dakosyExportStatus.sendToDakosy) {
      dakosyButtonColor = "success";
      dakosyButtonIcon = "mdi-check";
      dakosyButtonText = "Send to Dakosy";
    } else if (dakosyExportStatus === this.dakosyExportStatus.error) {
      dakosyButtonColor = "error";
      dakosyButtonIcon = "mdi-alert-circle-outline";
      dakosyButtonText = "Error";
    }

    return {
      dakosyButtonColor: dakosyButtonColor,
      dakosyButtonIcon: dakosyButtonIcon,
      dakosyButtonText: dakosyButtonText,
      dakosyButtonLoading: false,
    } as DakosyExportButtonProperties;
  }

  async exportToDakosy(item: EnrichedCustomsHandlingConsolidationViewModel) {
    if (item.dakosyExportStatus !== this.dakosyExportStatus.createExport) {
      return;
    }

    this.currentItem = item;
    this.showDialogAusfuhrInfo = true;
    item.dakosyButtonLoading = false;
  }

  async exportToDakosyConfirm(data: DakosyExportData) {
    if (!this.currentItem) {
      return;
    }

    this.currentItem.dakosyButtonLoading = true;
    try {
      await api.createExportDeclaration({
        endDate: data.endDate ?? "",
        presentationDate: data.presentationDate ?? "",
        shipmentId: this.currentItem.id,
        useShipperAddress: data.useShipperAddress,
        abdEmail: data.aBDEmail,
        articles: data.articles.map(
          (c) =>
            ({
              id: c.id,
              wtn: c.wtn,
              goodsDescription: c.goodsDescription,
              ownWeight: c.ownWeight,
              grossWeight: c.grossWeight,
              rchsCode: c.rchsCode,
              invoiceAmount: c.invoiceAmount,
              invoiceAmountCurrency: c.invoiceCurrency,
              freightCostAmount: c.freightCostsAmount,
              freightCostAmountCurrency: c.freightCostsCurrency,
              statAmount: c.statAmount,
              statAmountUnit: c.statUnit,
            }) as CreateDakosyExportRequestArticle,
        ),
        ausfuhrInfoId: data.addressId,
        ausfuhrzollstelle: data.ausfuhrzollstelle,
        avmEmail: data.aVMEmail,
        remarkSpecialCircumstancesCharacteristics:
          data.remarkSpecialCircumstancesCharacteristics,
        specialCircumstancesCharacteristics:
          data.specialCircumstancesCharacteristics,
        vorgeseheneAusgangszollstelle: data.vorgeseheneAusgangszollstelle,
        zusatz: data.zusatz,
      });
      const currentId = this.currentItem.id;
      const foundItem = this.items.find((c) => c.id === currentId);
      if (foundItem) {
        foundItem.dakosyExportStatus = this.dakosyExportStatus.createdExport;
      }
    } catch (e: unknown) {
      emitErrorWithFallback(
        e,
        "Something went wrong while during the Dakosy export",
      );
    } finally {
      this.showDialogAusfuhrInfo = false;
      this.currentItem.dakosyButtonLoading = false;
      this.currentItem = null;
    }
  }

  openAddExportMrnDialog(item: EnrichedCustomsHandlingConsolidationViewModel) {
    this.currentItem = item;
    this.showAddExportMrnDialog = true;
  }

  async onConfirmAddExportMrn(formData: AddMrnForm) {
    try {
      this.addExportMrnLoading = true;

      if (this.currentItem === null) {
        emitError(...["Current item is null. This shouldn't happen."]);
        return;
      }

      await api.addExportMrnToConsolidationShipment({
        exportMrn: formData.mrn,
        shipmentId: this.currentItem?.id,
      });

      this.currentItem!.exportMrn = formData.mrn;
      this.currentItem = null;
      this.showAddExportMrnDialog = false;
    } catch {
      emitError("Something went wrong while adding the MRN.");
    }
    this.addExportMrnLoading = false;
  }
}
